import React, { Fragment } from 'react';
const ms = require('pretty-ms')
export default class Timer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            time: 0,
            isOn: false,
            start: 0
        }
        this.startTimer = this.startTimer.bind(this)
        this.stopTimer = this.stopTimer.bind(this)
        this.resetTimer = this.resetTimer.bind(this)
    }
    componentDidMount() {
        this.startTimer();
    }
    componentWillUnmount() {
        this.stopTimer();
    }
    startTimer() {
        this.setState({
            isOn: true,
            time: this.state.time,
            start: Date.now() - this.state.time
        })
        this.timer = setInterval(() => this.setState({
            time: Date.now() - this.state.start
        }), 1);
    }
    stopTimer() {
        this.setState({ isOn: false })
        clearInterval(this.timer)
    }
    resetTimer() {
        this.setState({ time: 0, isOn: false })
    }
    render() {
        return (
            <Fragment>
                {ms(this.state.time)}
            </Fragment>

        )
    }
}