import React from 'react';
import PropTypes from 'prop-types';
import Button from './Button';

export const Buttons = ({ buttons,isGroup,className}) => {
    return (
        <div className={className+ (isGroup ? " btn-group":"")} role={isGroup ? "group":""}>
            {buttons.map((button,index) => 
                 <Button id="btn-g" key={index} {...button} style={{ margin:isGroup? 0 : "5px"}}  />
            )}
        </div>
    );
};
Buttons.defaultProps = {
    isGroup:false
  };
Buttons.propTypes = {
    buttons: PropTypes.array.isRequired,
    isGroup:PropTypes.bool,
    className:PropTypes.string
};

export default Buttons;
