import React from 'react';
import PropTypes from 'prop-types';
const backdrop = document.createElement('div');
backdrop.classList.add('modal-backdrop');
backdrop.style.opacity = 0.5;
let modalCount = 0;
class Modal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        };

    }

    componentDidMount() {
        modalCount = modalCount + 1;
        document.body.classList.add('modal-open');
        document.body.appendChild(backdrop);
    }

    componentWillUnmount() {
        modalCount = modalCount - 1;
        if (modalCount === 0) {
            document.body.classList.remove('modal-open');
            document.body.removeChild(backdrop);
        }
    }
    render() {
        const { className, title, onClose, footer, children } = this.props;
        const style = {
            display: 'block',
        };
        return (
            <div className={" "}>
                <div
                    className="modal"
                    style={style}
                    role="dialog"
                >
                    <div className={`modal-dialog ${className}`} role="document">
                        <div className="modal-content ">
                            <div className="modal-header container" >
                                <div className="modal-title col-lg-10"><h5>{title}</h5></div>
                                <div>
                                    <button className="close float-right " onClick={onClose}>
                                        <span >&times;</span>
                                    </button>
                                </div>
                            </div>
                            <div className="modal-body">
                                {children}
                            </div>
                            {footer && <div className="modal-footer">
                                {footer}
                            </div>}
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

Modal.propTypes = {
    title: PropTypes.string,
    className: PropTypes.string,
    onClose: PropTypes.func,
    footer: PropTypes.element,
    children: PropTypes.element

};

export default Modal;