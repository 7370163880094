import React, { Component } from "react";
import authService from "../api-authorization/AuthorizeService";
import DeleteModal from "../Modals/DeleteModal";
import Modal from "../Modals/Modal";
import Spinner from "../Common/Spinner";
import Timer from "../Common/Timer";
import { handleResponse } from "../../helpers/handleResponse";
import UserTable from './UserTable';
export class Users extends Component {
  
  static displayName = Users.name;

  constructor(props) {
    super(props);
    this.state = {
      allowedUsers: [],
      loading: false,
      deactivateUser: null,
      userEmail:''
    };
    this.onInputChange = this.onInputChange.bind(this);
    this.onDeleteConfirmed = this.onDeleteConfirmed.bind(this);
    this.handleChangeUserStatus = this.handleChangeUserStatus.bind(this);
    this.handleSubmitUser = this.handleSubmitUser.bind(this);
  }

  componentDidMount() {
    this.getAllUsers();
  }
  onDeleteConfirmed() {
    const { deactivateUser } = this.state;
    this.changeUserStatus(deactivateUser,false);
    this.setState({ deactivateUser: undefined });
  }
  onInputChange(e) {
    this.setState({
      userEmail: e.target.value
    });
  }
  handleSubmitUser(){
    const { userEmail } = this.state;
    this.setState({createUserModal:false});
    if(userEmail !==''&& userEmail!=null){
      let user={};
      user.email=userEmail;
      user.id=0;
      user.userRole=0;
      user.isActive=false;
      this.createUser(user)
    }
  }
  handleChangeUserStatus(allowedUser,isActive){
    if(!isActive){
      this.setState({ deactivateUser: allowedUser });
    }else{
      this.changeUserStatus(allowedUser,isActive);
    }
   
  }
   
  render() {
    const { deactivateUser,createUserModal,userEmail } = this.state;
    let contents = this.state.loading ? (
      <div>
        <Spinner />
        <div className="container d-flex justify-content-center">
          <Timer />
        </div>
      </div>
    ) : (<UserTable
      allowedUsers={this.state.allowedUsers}
      changeUserStatus={this.handleChangeUserStatus}
    />
    );
    return (
      <div>
        
          <div >
            <button className="btn btn-info float-right mr-4 mb-1"
            onClick={()=> this.setState({createUserModal:true})} >
              Create User
            </button>
          </div> 
         { createUserModal && <Modal
           title="Create User"
           className=""
           onClose ={()=>this.setState({createUserModal:false})}
           footer = {<button  disabled={!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(userEmail)} className="btn btn-primary" onClick={this.handleSubmitUser}>Create</button>}
           children={<div><label htmlFor={"email"}>Email:</label><input type="text " name="email" className="form-control form-control-sm" onChange={this.onInputChange} ></input>
           <p className="text-danger">{!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(userEmail)?"Not Valid Email":""}</p></div>}
         />}
          
        

        {deactivateUser && (
          <DeleteModal
            name={deactivateUser.email}
            question="Are you sure you want to deactivate email :"
            note="User will be removed completly , user should register again if reactivated"
            onConfirm={this.onDeleteConfirmed}
            onClose={() => this.setState({ deactivateUser: null })}
          />
        )}{
        }
        {contents}
      </div>
    );
  }
 
  async getAllUsers() {
    this.setState({ allowedUsers: null, loading: true });
    const token = await authService.getAccessToken();

    const response = await fetch(`api/users/get`, {
      method: "Get",
      headers: !token
        ? {}
        : {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
          }
    });
    const data = await handleResponse(response, this.props.history,false);

    if (data) {
      this.setState({ allowedUsers: data, loading: false });
    } else {
      this.setState({ result: this.getFailedResult(), loading: false });
    }
  }
  async changeUserStatus(allowedUser,isActive){
   this.setState({ allowedUsers: null, loading: true });
   const {allowedUsers} =this.state;
    const token = await authService.getAccessToken();
    const response = await fetch(`api/users/status/${isActive}`, {
      method: "POST",
      body: JSON.stringify(allowedUser),
      headers: !token
        ? {}
        : {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
          }
    });
    const data = await handleResponse(response, this.props.history,false);

    if (data) {
      let index=allowedUsers.findIndex(function(u){return u.id===data.id });
      let updatedAllowedUsers=allowedUsers;
      if(index != null){
        updatedAllowedUsers[index]=data;
        this.setState({ allowedUsers: updatedAllowedUsers, loading: false });
      }
      
    } else {
      //this.setState({ result: this.getFailedResult(), loading: false });
    }
  }
  async createUser(allowedUser) {
    const { allowedUsers } = this.state;
    this.setState({ loading: true });
    const token = await authService.getAccessToken();

    const response = await fetch(`api/users/create`, {
      method: "POST",
      body: JSON.stringify(allowedUser),
      headers: !token
        ? {}
        : {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
          }
    });
    const data = await handleResponse(response, this.props.history,false);

    if (data) {
      let updatedUsers=[...allowedUsers,data];
      this.setState({ allowedUsers: updatedUsers, loading: false });
    } else {
     // this.setState({ result: this.getFailedResult(), loading: false });
    }
  }
}