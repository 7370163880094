import React, { Component } from "react";
import "font-awesome/css/font-awesome.min.css";
export default class Spinner extends Component {
  render() {
    return (
      <div className=" container d-flex justify-content-center">
        <div
          style={{ height: "100%", width: "100%" }}
          className="container text-center d-flex justify-content-center align-items-center"
        >
          <i className="fa fa-refresh fa-spin fa-3x"></i>
        </div>
      </div>
    );
  }
}
