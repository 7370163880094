import React, { Component } from 'react';
import { NavMenu } from './NavMenu';
import authService from './api-authorization/AuthorizeService';
export class Layout extends Component {
  static displayName = Layout.name;
  constructor(props) {
    super(props);

    this.state = {
        ready: false,
        authenticated: false
    };
}


componentDidMount() {
  this._subscription = authService.subscribe(() => this.authenticationChanged());
  this.populateAuthenticationState();
}

componentWillUnmount() {
  authService.unsubscribe(this._subscription);
}

  render () {
    const { authenticated } = this.state;
    return (
      <div>
      {authenticated?  <NavMenu />:null}
       
          {this.props.children}
       
      </div>
    );
  }

  async populateAuthenticationState() {
    const authenticated = await authService.isAuthenticated();
    this.setState({ ready: true, authenticated });
}

async authenticationChanged() {
    this.setState({ ready: false, authenticated: false });
    await this.populateAuthenticationState();
}
}
