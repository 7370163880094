import React  from 'react';
import PropTypes from 'prop-types';
const Button = ({ name, label, onClick, value, className, disabled, fontAwesome, secondfontAwesome, type, style }) => {
  return (
       <button

          name={name}
          value={value}
          type={type}
          onClick={onClick}
          className={className}
          style={style}
          disabled={disabled}>
          <i className={fontAwesome}></i>
          <i className={secondfontAwesome}></i>
          {fontAwesome && label ? <span>&nbsp;</span> : null}
          {label}
      </button>
        );
      };
Button.defaultProps = {
  className: 'btn btn-primary',
};
Button.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  secondfontAwesome: PropTypes.string,
  fontAwesome: PropTypes.string,
  type: PropTypes.string,
  style: PropTypes.object
};

export default Button;
