import React from "react";
import PropTypes from "prop-types";
import {
  HubConnectionBuilder
} from "@aspnet/signalr";
import moment from 'moment';
import * as _ from "lodash";

const messageType = {
  error: "ERROR",
  info: "INFO",
  warning: "WARNING"
};
class Logs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logs: [],
      showLogs: true,
      connection: new HubConnectionBuilder()
        .withUrl("/logs")
        //.configureLogging(signalR.LogLevel.Information)
        //  .withAutomaticReconnect({
        //       nextRetryDelayInMilliseconds: retryContext => {
        //           if (retryContext.elapsedMilliseconds < 60000) {
        //               // If we've been reconnecting for less than 60 seconds so far,
        //               // wait between 0 and 10 seconds before the next reconnect attempt.
        //               return Math.random() * 10000;
        //           } else {
        //               // If we've been reconnecting for more than 60 seconds so far, stop reconnecting.
        //               return null;
        //           }
        //       }
        //   })
        .build()
    };
    this.saveMessage = this.saveMessage.bind(this);
  }

  componentDidMount() {
    const { connection } = this.state;
    connection.start().then(() => console.log("Starting signal R connection")); //connection.invoke("send", "Hello")
    connection.on("info", message => {
      this.saveMessage(message, messageType.info);
    });
    connection.on("error", message => {
      this.saveMessage(message, messageType.error);
    });
    connection.on("warning", message => {
      this.saveMessage(message, messageType.warning);
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { clearLogs,forceHideLogs } = this.props;
    if (prevProps && prevProps.clearLogs !== clearLogs) {
      this.setState({ logs: [], showLogs: true });
    }
    if (prevProps && prevProps.forceHideLogs !== forceHideLogs) {
      this.setState({ showLogs: false });
    }
  }
  saveMessage(message, type) {
    const { logs } = this.state;
    

    this.setState({
      logs: [...logs, { type: type, message: message, time: moment.now() }]
    });
  }
  componentWillUnmount() {
    // const { connection } = this.state;
    // connection.stop().then(function() {
    //   console.log("stopped");
    // });
  }
  render() {
    const { logs, showLogs } = this.state;
    return (
      <div className={""} style={{ display: logs.length ? "block" : "none" }}>
        {logs.length ? (
          <button
            className={" btn btn-sm btn btn-outline-primary float-right m-1"}
            onClick={() => this.setState({ showLogs: !this.state.showLogs })}
          >
            {showLogs ? "Hide" : "show"} logs
          </button>
        ) : null}
        <div
          style={{ display: showLogs ? "block" : "none" }}
          className="box-border"
        >
          {_.orderBy(logs, "time", "desc").map((m, index) => (
            <div key={index}>
              <span style={{ fontSize: "10px" }}>
                [{moment(m.time).format("HH:mm:ss.SSSZ")}]
              </span>
              :
              <span
                className={
                  m.type === messageType.error
                    ? "text-danger"
                    : m.type === messageType.warning
                    ? "text-warning"
                    : m.type === messageType.info
                    ? "text-info"
                    : ""
                }
              >
                {m.type}
              </span>
              :<span> {m.message}</span>
            </div>
          ))}
        </div>
        {(!logs.length || logs.length) === 0 ? (
          <div>Nothing to display</div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

Logs.propTypes = {
  clearLogs: PropTypes.bool,
  forceHideLogs: PropTypes.bool
};

export default Logs;
