import React, { Component } from "react";
import authService from "../api-authorization/AuthorizeService";
import DeleteModal from "../Modals/DeleteModal";
import Modal from "../Modals/Modal";
import Spinner from "../Common/Spinner";
import Timer from "../Common/Timer";
import { handleResponse } from "../../helpers/handleResponse";
import SupplierTable from './SupplierTable';
import TextInput from '../Common/TextInput';
import TextArea from '../Common/TextArea';
import 'font-awesome/css/font-awesome.min.css';
const InputTypes={Input:0,TextArea:1,Select:2};
const supplierInputs=[
    {   name: "name",
        label: "Name",
        isRequerd:true,
        inputType:InputTypes.Input
    },{
        name:"shippingInfo",
        label: "Shipping Info",
        isRequerd:true,
        inputType:InputTypes.Input
    },{
        name:"warrentyInfo",
        label:"Warrenty Info",
        isRequerd:true,
        inputType:InputTypes.Input
    },
    {
      name:"descriptionFooter",
      label:"Description Footer",
      isRequerd:true,
      inputType:InputTypes.TextArea
    }
    //,
    //{
    //    name: "supplierName",
    //    inputType: InputTypes.Select
    //}
];
const priceMarginsFields=[
  {   name: "min",
      label: "Min",
      type:"number",
      inputType:InputTypes.Input,
      containerClassName:"col-3"
  },{
      name:"max",
      label: "Max",
      type:"number",
      inputType:InputTypes.Input,
      containerClassName:"col-3"
  },{
      name:"fixed",
      label:"Shipping Fee",
      type:"number",
      inputType:InputTypes.Input,
      containerClassName:"col-2"
  },
  {
    name:"percentage",
    label:"Percentage",
    type:"number",
    inputType:InputTypes.Input,
    containerClassName:"col-2"
}
];
const newPriceMargin={id:0,min:0,max:0,fixed:0,percentage:0};
const newSupplier = { name: '', shippingInfo: '', warrentyInfo: '', descriptionFooter: '', priceMargins: [newPriceMargin], supplierName:0};
const replacementWordInputs=[
  {   name: "original",
      label: "Original",
      isRequerd:true,
      inputType:InputTypes.Input
  },{
      name:"replacement",
      label: "Replacement",
      isRequerd:true,
      inputType:InputTypes.Input
  }
];
const newReplacementWord = {};

export class Suppliers extends Component {
  static displayName = Suppliers.name;

  constructor(props) {
    super(props);
    this.state = {
      suppliers: [],
      loading: false,
      supplier: newSupplier,
      supplierInputs: supplierInputs,
      modalName: "",
      priceMarginsError: "",
      deleteSupplier: undefined,
      deleteReplacementWord: undefined,
      replacementWord: newReplacementWord,
      replacementWordInputs:replacementWordInputs
    };
    this.onDeleteConfirmed = this.onDeleteConfirmed.bind(this);
    this.handleSubmitSupplier = this.handleSubmitSupplier.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.validateInput = this.validateInput.bind(this);
    this.renderPriceMargin = this.renderPriceMargin.bind(this);
    this.onChangePriceMarginValues = this.onChangePriceMarginValues.bind(this);
    this.addPriceMargin = this.addPriceMargin.bind(this);
    this.removePriceMargin = this.removePriceMargin.bind(this);
    this.validatePriceMargins = this.validatePriceMargins.bind(this);
    this.onDeleteReplacmentWordConfirmed = this.onDeleteReplacmentWordConfirmed.bind(
      this
    );
    this.handleReplacmentInputChange = this.handleReplacmentInputChange.bind(
      this
    );
    this.handleSubmitReplacementWord = this.handleSubmitReplacementWord.bind(this);
  }

  componentDidMount() {
    this.getAllsuppliers();
  }
  onDeleteConfirmed() {
    const { deleteSupplier } = this.state;
    this.deleteSupplier(deleteSupplier.id);
  }
  onDeleteReplacmentWordConfirmed() {
    const { deleteReplacementWord } = this.state;
    this.deleteWord(deleteReplacementWord.id,deleteReplacementWord.supplierId);
  }
  validateInput(event, index) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    let input = supplierInputs.find((s) => s.name === name);
    if (input && input.name === name && input.isRequerd && !value) {
      supplierInputs[index].error = "This field required";
      this.setState({ supplierInputs: supplierInputs });
    }
  }
  validateReplacementWordInput(event, index) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    let input = replacementWordInputs.find((w) => w.name === name);
    if (input && input.name === name && input.isRequerd && !value) {
      replacementWordInputs[index].error = "This field required";
      this.setState({ replacementWordInputs: replacementWordInputs });
    }
  }
  handleReplacmentInputChange(event, index) {
    const { replacementWord } = this.state;
    const value = event.target.value;
    const name = event.target.name;
    if (replacementWordInputs[index].error && value) {
      replacementWordInputs[index].error = undefined;
      this.setState({
        replacementWord: {
          ...replacementWord,
          [name]: value,
          replacementWordInputs: replacementWordInputs,
        },
      });
    } else {
      this.setState({
        replacementWord: { ...replacementWord, [name]: value },
      });
    }
  }
  handleInputChange(event, index) {
    const { supplier, supplierInputs } = this.state;
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    if (supplierInputs[index].error && value) {
      supplierInputs[index].error = undefined;
      this.setState({
        supplier: {
          ...supplier,
          [name]: value,
          supplierInputs: supplierInputs,
        },
      });
    } else {
      this.setState({
        supplier: { ...supplier, [name]: value },
      });
    }
  }
  handleSupplierNameChange(event) {
    const { supplier } = this.state;
    const target = event.target;
    const value = parseInt(target.value);
    this.setState({
      supplier: { ...supplier, supplierName: value },
    });
  }
  handleSubmitSupplier() {
    const { supplier, supplierInputs } = this.state;
    supplierInputs.forEach((si) => {
      if (!supplier[si.name]) {
        supplierInputs.find((s) => s.name === si.name).error =
          "This field required";
        this.setState({ supplierInputs: supplierInputs });
      }
    });
    if (!supplierInputs.find((s) => s.error !== undefined)) {
      if (supplier.id) {
        if (this.validatePriceMargins(supplier.priceMargins)) {
          this.updateSupplier({ ...supplier });
        }
      } else {
        this.createSupplier({ ...supplier, id: 0 });
      }
    }
  }
  handleSubmitReplacementWord() {
    const { replacementWord } = this.state;
    replacementWordInputs.forEach((rw) => {
      if (!replacementWord[rw.name]) {
        replacementWordInputs.find((w) => w.name === rw.name).error =
          "This field required";
        this.setState({ replacementWordInputs: replacementWordInputs });
      }
    });
    if (!replacementWordInputs.find((w) => w.error !== undefined)) {
      this.createReplacementWord(replacementWord);
    }
  }
  validatePriceMargins(priceMargins) {
    let isValid = true;
    priceMargins.forEach((pm, index) => {
      if (index > 0 && index < priceMargins.length) {
        if (pm.max !== priceMargins[index - 1].min - 0.01) {
          this.setState({
            priceMarginsError:
              "Min and Max should be consequtive with 0.01 margin.",
          });
          isValid = false;
        }
      }
      if (index === priceMargins.length - 1 && pm.min !== 0) {
        this.setState({
          priceMarginsError: "Last price Margin minimum value should be zero",
        });
        isValid = false;
      }
      if (pm.min >= pm.max && pm.max !== 0) {
        this.setState({
          priceMarginsError:
            "Margin minimum value can't be greater than max value ",
        });
        isValid = false;
      }
    });
    return isValid;
  }
  onChangePriceMarginValues(event, index) {
    const { supplier, priceMarginsError } = this.state;
    const target = event.target;
    const value =
      target.type === "checkbox"
        ? target.checked
        : target.type === "number"
        ? target.value === undefined ||
          target.value === null ||
          target.value === ""
          ? 0
          : parseFloat(target.value)
        : target.value;
    const name = target.name;
    let updatedMargin = { ...supplier.priceMargins[index], [name]: value };
    let updatedMargins = supplier.priceMargins;
    updatedMargins[index] = updatedMargin;
    let updatedSupplier = { ...supplier, priceMargins: updatedMargins };
    if (priceMarginsError) {
      this.setState({ supplier: updatedSupplier, priceMarginsError: "" });
    } else {
      this.setState({ supplier: updatedSupplier });
    }
  }

  addPriceMargin() {
    const { supplier, priceMarginsError, priceMargins } = this.state;
    let margins = supplier.priceMargins;
    let margin = newPriceMargin;
    if (margins.length > 0) {
      margin.max = margins[margins.length - 1].min - 0.01;
    }
    if (priceMarginsError) {
      this.setState({
        supplier: { ...supplier, priceMargins: [...margins, margin] },
        priceMarginsError: "",
      });
    } else {
      this.setState({
        supplier: { ...supplier, priceMargins: [...margins, margin] },
      });
    }
  }
  removePriceMargin(index) {
    const { supplier, priceMarginsError } = this.state;
    let margins = supplier.priceMargins.filter(
      (p) => p !== supplier.priceMargins[index]
    );
    if (priceMarginsError) {
      this.setState({
        supplier: { ...supplier, priceMargins: margins },
        priceMarginsError: "",
        supplierName: 0,
      });
    } else {
      this.setState({ supplier: { ...supplier, priceMargins: margins } });
    }
  }
  renderPriceMargin(p, i) {
    return (
      <div className="row">
        {priceMarginsFields.map((input, index) =>
          input.inputType === InputTypes.Input ? (
            <TextInput
              {...input}
              onChange={(e) => this.onChangePriceMarginValues(e, i)}
              //onBlur={(e)=>this.validateInput(e,index)}
              key={index}
              value={
                input.name === "max" && i === 0 ? "Unlimited" : p[input.name]
              }
              disabled={input.name === "max" && i === 0}
              type={input.name === "max" && i === 0 ? "text" : input.type}
            />
          ) : null
        )}
        {i !== 0 ? (
          <button
            className={"btn btn-sm btn-light text-danger"}
            onClick={() => this.removePriceMargin(i)}
          >
            <i className="fa fa-trash"></i>
          </button>
        ) : null}
      </div>
    );
  }
  render() {
    const {
      createSupplierModal,
      createReplacementWordModal,
      supplier,
      supplierInputs,
      modalName,
      priceMarginsError,
      deleteSupplier,
      deleteReplacementWord,
      replacementWord
    } = this.state;
    let contents = this.state.loading ? (
      <div>
        <Spinner />
        <div className="container d-flex justify-content-center">
          <Timer />
        </div>
      </div>
    ) : (
      <SupplierTable
        suppliers={this.state.suppliers}
        onEdit={(supplier) =>
          this.setState({
            supplier: supplier,
            createSupplierModal: true,
            modalName: "Edit",
          })
        }
        onDelete={(supplier) => this.setState({ deleteSupplier: supplier })}
        onEditReplacmentWord={(w) => console.log("edit", w)}
        onDeleteReplacmentWord={(w) =>
          this.setState({ deleteReplacementWord: w })
        }
        onCreateReplacmentWord={(s) =>
          this.setState({
            replacementWord: { ...newReplacementWord, supplierId: s.id },
            createReplacementWordModal: true
          })
        }
      />
    );
    return (
      <div>
        <div>
          <button
            className="btn btn-info float-right mr-4 mb-1"
            onClick={() =>
              this.setState({
                createSupplierModal: true,
                modalName: "Create",
                supplier: newSupplier,
              })
            }
          >
            <i className="fa fa-plus"></i> Create Supplier Config
          </button>   
        </div>
        {createSupplierModal && (
          <Modal
            title={modalName + " Supplier Config"}
            className="modal-lg"
            onClose={() => this.setState({ createSupplierModal: false })}
            footer={
              <button
                type="submit"
                className="btn btn-primary"
                onClick={this.handleSubmitSupplier}
              >
                {modalName}
              </button>
            }
            children={
              <div>
                {supplierInputs.map((input, index) =>
                  input.inputType === InputTypes.Input ? (
                    <TextInput
                      {...input}
                      onChange={(e) => this.handleInputChange(e, index)}
                      onBlur={(e) => this.validateInput(e, index)}
                      key={index}
                      value={supplier[input.name]}
                    />
                  ) : input.inputType === InputTypes.TextArea ? (
                    <TextArea
                      {...input}
                      onChange={(e) => this.handleInputChange(e, index)}
                      onBlur={(e) => this.validateInput(e, index)}
                      key={index}
                      value={supplier[input.name]}
                    />
                  ) : null
                )}
                {modalName !== "Edit" ? (
                  <div className="form-group">
                    <label htmlFor="supplierName">Supplier</label>
                    <div className="field">
                      <select
                        className="form-control"
                        onChange={(e) => this.handleSupplierNameChange(e)}
                        name="supplierName"
                      >
                        <option key="0" value={0}>
                          EMPR
                        </option>
                        <option key="1" value={1}>
                          Zand
                        </option>
                      </select>
                    </div>
                  </div>
                ) : null}

                {supplier.priceMargins
                  ? supplier.priceMargins.map((p, index) => (
                      <div key={index} className="col-12 row">
                        {this.renderPriceMargin(p, index)}
                      </div>
                    ))
                  : null}
                <div className="d-flex justify-content-center">
                  {priceMarginsError ? (
                    <div className="text-danger">{priceMarginsError}</div>
                  ) : null}
                </div>

                <div className="d-flex justify-content-center">
                  {supplier &&
                  supplier.priceMargins.length &&
                  supplier.priceMargins[supplier.priceMargins.length - 1].min <=
                    0 ? (
                    <span className="text-warning" style={{ fontSize: "12px" }}>
                      To add new margin last margin min value should be positive
                    </span>
                  ) : (
                    <button
                      className={"btn btn-sm btn-light text-success"}
                      onClick={this.addPriceMargin}
                      disabled={
                        supplier &&
                        supplier.priceMargins.length &&
                        supplier.priceMargins[supplier.priceMargins.length - 1]
                          .min <= 0
                      }
                    >
                      <i className="fa fa-plus"></i> Add Price Margin
                    </button>
                  )}
                </div>
              </div>
            }
          />
        )}
        {createReplacementWordModal && (
          <Modal
            title="Create replacement word" 
            className="modal-lg"
            onClose={() => this.setState({ createReplacementWordModal: false })}
            footer={
              <button
                type="submit"
                className="btn btn-primary"
                onClick={this.handleSubmitReplacementWord}
              >
                {"create"}
              </button>
            }
            children={
              <div>
                 {replacementWordInputs.map((input, index) =>
                   <TextArea
                   {...input}
                   onChange={(e) => this.handleReplacmentInputChange(e, index)}
                   onBlur={(e) => this.validateReplacementWordInput(e, index)}
                   key={index}
                   value={replacementWord[input.name]}
                 />)
                 }
                </div>         
            }
          />
        )}
        {deleteSupplier !== undefined ? (
          <DeleteModal
            name={deleteSupplier.name}
            onConfirm={this.onDeleteConfirmed}
            onClose={() => this.setState({ deleteSupplier: undefined })}
            note="This config will be no longer availiable"
            question="Are you sure you want to delete supplier config"
          />
        ) : null}
        {deleteReplacementWord !== undefined ? (
          <DeleteModal
            name={deleteReplacementWord.original}
            onConfirm={this.onDeleteReplacmentWordConfirmed}
            onClose={() => this.setState({ deleteReplacementWord: undefined })}
            note=""
            question="Are you sure you want to delete"
          />
        ) : null}
        {contents}
      </div>
    );
  }

  async getAllsuppliers() {
    this.setState({ suppliers: null, loading: true });
    const token = await authService.getAccessToken();

    const response = await fetch(`api/users/suppliers`, {
      method: "Get",
      headers: !token
        ? {}
        : {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
    });
    const data = await handleResponse(response, this.props.history, false);

    if (data) {
      this.setState({ suppliers: data, loading: false });
    } else {
      this.setState({ result: this.getFailedResult(), loading: false });
    }
  }
  async createSupplier(supplier) {
    const { suppliers } = this.state;
    this.setState({ supplier: {}, loading: true, createSupplierModal: false });
    const token = await authService.getAccessToken();
    const response = await fetch(`api/users/createSupplier`, {
      method: "POST",
      body: JSON.stringify({ ...supplier }),
      headers: !token
        ? {}
        : {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
    });
    const data = await handleResponse(response, this.props.history, false);

    if (data) {
      let updatedsuppliers = [...suppliers, data];
      this.setState({ suppliers: updatedsuppliers, loading: false });
    } else {
      this.setState({ loading: false });
    }
  }
  async createReplacementWord(replacementWord){
    const { suppliers } = this.state;
    this.setState({ replacementWord: {}, loading: true, createReplacementWordModal: false });
    const token = await authService.getAccessToken();
    const response = await fetch(`api/users/createReplacementWord`, {
      method: "POST",
      body: JSON.stringify({ ...replacementWord }),
      headers: !token
        ? {}
        : {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
    });
    const data = await handleResponse(response, this.props.history, false);

    if (data) {
      let updatedsuppliers = [...suppliers, data];
      this.setState({ suppliers: updatedsuppliers, loading: false });
    } else {
      this.setState({ loading: false });
    }
  }
  async updateSupplier(supplier) {
    const { suppliers } = this.state;
    this.setState({ supplier: {}, loading: true, createSupplierModal: false });
    const token = await authService.getAccessToken();
    const response = await fetch(`api/users/updateSupplier`, {
      method: "PUT",
      body: JSON.stringify(supplier),
      headers: !token
        ? {}
        : {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
    });
    const data = await handleResponse(response, this.props.history, false);

    if (data) {
      let index = suppliers.findIndex(function (s) {
        return s.id === data.id;
      });
      let updatedSuppliers = suppliers;
      if (index !== null) {
        updatedSuppliers[index] = data;
        this.setState({ suppliers: updatedSuppliers, loading: false });
      }
    } else {
      this.setState({ loading: false });
    }
  }
  async deleteSupplier(supplierId) {
    const { suppliers } = this.state;
    this.setState({ deleteSupplier: undefined, loading: true });
    const token = await authService.getAccessToken();
    const response = await fetch(`api/users/deleteSupplier/${supplierId}`, {
      method: "Delete",
      headers: !token
        ? {}
        : {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
    });
    if (response && response.ok) {
      let updatedsuppliers = suppliers.filter((s) => s.id !== supplierId);
      this.setState({ suppliers: updatedsuppliers, loading: false });
    } else {
      this.setState({ loading: false });
    }
  }
  async deleteWord(id,supplierId) {
    const { suppliers } = this.state;
    this.setState({ deleteReplacementWord: undefined, loading: true });
    const token = await authService.getAccessToken();
    const response = await fetch(`api/users/deleteReplacementWord/${id}`, {
      method: "Delete",
      headers: !token
        ? {}
        : {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
    });
    if (response && response.ok) {
      let updatedSupplier = suppliers.find(s=>s.id==supplierId);
       let updatedsuppliers=suppliers.filter(s=>s.id!==supplierId);
       updatedSupplier.words = updatedSupplier.words.filter(w=>w.id!==id);
       updatedsuppliers=[...updatedsuppliers,updatedSupplier];
       this.setState({ suppliers: updatedsuppliers, loading: false, });
    } else {
      this.setState({ loading: false });
    }
  }
  async createReplacementWord(word) {
    const { suppliers } = this.state;
    this.setState({
      word: {},
      loading: true,
      createReplacementWordModal: false,
    });
    const token = await authService.getAccessToken();
    const response = await fetch(`api/users/createReplacementWord`, {
      method: "POST",
      body: JSON.stringify({ ...word }),
      headers: !token
        ? {}
        : {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
    });
    const data = await handleResponse(response, this.props.history, false);

    if (data) {
      let updatedsuppliers=suppliers.filter(s=>s.id!==data.id);
          updatedsuppliers=[...updatedsuppliers,data];
      this.setState({ suppliers: updatedsuppliers, loading: false, });
    } else {
      this.setState({ loading: false });
    }
  }
}

              