import React from 'react';
import PropTypes from 'prop-types';
import Button from './Button';
import 'font-awesome/css/font-awesome.min.css';

 const TableExpandButton = ({ isExpanded,onClick}) => {
    return (
        <div >
            <Button
               className="btn btn-xs btn-outline-secondary float-right"
               //label={""}
               onClick={onClick}
               fontAwesome={"fa fa-1x  fa-angle-down rotate" + (isExpanded ? " r-180" : " ")}//<h6 className={(isExpanded ? "r-270" : "")}></h6>
            />
        </div>
    );
};
TableExpandButton.defaultProps = {

  };
TableExpandButton.propTypes = {
    isExpanded: PropTypes.oneOfType([PropTypes.object,PropTypes.bool]),
    onClick: PropTypes.func.isRequired
};

export default TableExpandButton;