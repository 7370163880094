import React, { Component } from 'react';

export class Home extends Component {
  static displayName = Home.name;

  render () {
      return (
          <div className=" container d-flex justify-content-center">
            <img src="https://laptopparts.com.au/pub/media/logo/stores/1/logo_big_trans.png" alt=""></img>
      </div>
    );
  }
}
