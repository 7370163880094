import React from 'react';
import PropTypes from 'prop-types';
import Modal from './Modal';
export const PhotoModal = ({ onClose, photoData, className }) => {
    return (
        <Modal
            className={className}
            children={<img className="modal-content" src={photoData} alt="" />}
            onClose={onClose}
        />
    );
};
PhotoModal.defaultProps = {
    className: "mh-75  zoomIn",
    photoData: "https://laptopparts.com.au/pub/media/favicon/stores/1/lp_favicon_copy.png"
}
PhotoModal.propTypes = {
    onClose: PropTypes.func,
    photoData: PropTypes.string,
    className: PropTypes.string
};
export default PhotoModal