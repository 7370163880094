export const categories =
    [{
        text: "HP Compaq Laptop Parts",
        value: 5
    },
    {
        text: "Toshiba Laptop Parts",
        value: 3

    },
    {
        text: "Acer Laptop Parts",
        value: 9
    },
    {
        text: "IBM Lenovo Laptop Parts",
        value: 10
    },
    {
        text: "Dell Laptop Parts",
        value: 4
    },
    {
        text: "ASUS Laptop Parts",
        value: 11
    },
    {
        text: "Apple Laptop Parts",
        value: 6
    }];
