import React from 'react';
import PropTypes from 'prop-types';

export const TextInput = ({name, label, onChange, placeholder, value,type="text",className, error,disabled,onFocus,onBlur,containerClassName}) => {
  let wrapperClass = 'form-group';
  if (error && error.length > 0) {
    wrapperClass += " " + 'has-error';
  }

  return (
    <div className={wrapperClass+" "+containerClassName}>
      <label htmlFor={name}>{label}</label>
      <div className="field">
        <input
          type={type}
          name={name}
          className={className}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          disabled={disabled}/>
        {error? <div className="alert alert-danger">{error}</div>:null}
        {/* {error && <div className="alert alert-danger">{error}</div>}
        {touched && (error && <span className="field-error">{error}</span>)} */}
      </div>
    </div>
  );
};
TextInput.defaultProps = {
  className:"form-control form-control-sm",
  type: 'text',
};
TextInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string,PropTypes.number]),
  error: PropTypes.string,
  type:PropTypes.string,
  disabled:PropTypes.bool,
  className:PropTypes.string,
  meta: PropTypes.object,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  containerClassName: PropTypes.string
};

export default TextInput;
