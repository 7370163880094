import React, { Component, Fragment } from "react";
import authService from "./api-authorization/AuthorizeService";
import { validURL } from "../helpers/functions";
import { categories } from "../AppConstants/magentoCategoriesIds";
import PhotoModal from "./Modals/PhotoModal";
import Timer from "./Common/Timer";
import ConfirmModal from "./Modals/ConfirmModal";
import ExpandButton from "./Buttons/TableExpandButton";
import Spinner from './Common/Spinner';
import {handleResponse} from '../helpers/handleResponse';
import Logs from './SignalR/Logs';
import { Prompt } from 'react-router';
import {
    HubConnectionBuilder
} from "@aspnet/signalr";
const imageUrl =
    "https://laptopparts.com.au/pub/media/logo/stores/1/logo_big_trans.png";
function toCamel(o) {
    var newO, origKey, newKey, value
    if (o instanceof Array) {
        return o.map(function (value) {
            if (typeof value === "object") {
                value = toCamel(value)
            }
            return value
        })
    } else {
        newO = {}
        for (origKey in o) {
            if (o.hasOwnProperty(origKey)) {
                newKey = (origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey).toString()
                value = o[origKey]
                if (value instanceof Array || (value !== null && value.constructor === Object)) {
                    value = toCamel(value)
                }
                newO[newKey] = value
            }
        }
    }
    return newO
}
export class Empr extends Component {
         static displayName = Empr.name;

         constructor(props) {
           super(props);
           this.state = {
             parts: [],
             loading: false,
             url: "",
             parentCategoryId: undefined,
             supplierConfigId: undefined,
             modelName: "",
             modelNumber: "",
             searchBaseUrl:"",
             shippingInfo:"",
             warrentyInfo:"",
             descriptionFooter:"",
             processedUrl : "",
             processingUrl:"",
             confirmGetParts: false,
             clearLogs:false,
             forceHideLogs:false,
             suppliersConfig:[],
               partsSelected: false,
               connection: new HubConnectionBuilder()
                   .withUrl("/logs").build()
           };
           this.onInputChange                      = this.onInputChange.bind(this);
           this.onCategoryChange                   = this.onCategoryChange.bind(this);
           this.onModelNameChange                  = this.onModelNameChange.bind(this);
           this.onModelNumberChange                = this.onModelNumberChange.bind(this);
           this.onProcessProducts                  = this.onProcessProducts.bind(this);
           this.renderpartsTable                   = this.renderpartsTable.bind(this);
           this.onCheckboxChange                   = this.onCheckboxChange.bind(this);
           this.onGetProductsConfirmed             = this.onGetProductsConfirmed.bind(this);
           this.onPartNameChange                   = this.onPartNameChange.bind(this);
           this.renderDetailsTable                 = this.renderDetailsTable.bind(this);
           this.onSelectAllPhotos                  = this.onSelectAllPhotos.bind(this);
           this.renderLogs                         = this.renderLogs.bind(this);
           this.renderSelectSupplierConfigurations = this.renderSelectSupplierConfigurations.bind(this);
           this.onSupplierConfigChange             = this.onSupplierConfigChange.bind(this);
           this.renderSelectCategory               = this.renderSelectCategory.bind(this);
           this.onProcesCheckboxChange             = this.onProcesCheckboxChange.bind(this);
           this.onSelectAllParts                   = this.onSelectAllParts.bind(this);

          }

    componentDidMount() {
        this.getAllsuppliers();
        const { connection, processingUrl } = this.state;
        connection.start().then(() => console.log("Starting signal R connection"));
        connection.on("results", results => {
            let object = JSON.parse(results);
            let data = toCamel(object);
            if (data) {
                this.setState({
                    parts: data.parts,
                    modelNumber: data.modelNumber,
                    modelName: data.modelName,
                    searchBaseUrl: data.searchBaseUrl,
                    shippingInfo: data.shippingInfo,
                    warrentyInfo: data.warrentyInfo,
                    parentCategoryId: data.categoryId,
                    descriptionFooter: data.descriptionFooter,
                    loading: false,
                    processedUrl: processingUrl,
                    forceHideLogs: !this.state.forceHideLogs
                });
            } else {
                this.setState({ parts: [], loading: false });
            }
        });
        connection.on("processedResults", results => {
            let object = JSON.parse(results);
            let data = toCamel(object);
            console.log("processing Results: ", data);
            if (data) {
                this.setState({
                    parts: data.parts,
                    modelNumber: "",
                    modelName: "",
                    loading: false
                });
            } else {
                this.setState({ parts: [], loading: false });
            }
        });

    }

         onInputChange(e) {
           this.setState({
             url: e.currentTarget.value
           });
         }
         onCategoryChange(e) {
           let { value } = e.target;
           this.setState({
             parentCategoryId: parseInt(value) || 0
           });
         }
         onSupplierConfigChange(e) {
          let { value } = e.target;
          this.setState({
            supplierConfigId: parseInt(value) || 0
          });
        }
         onModelNameChange(e) {
           this.setState({
             modelName: e.currentTarget.value
           });
         }
         onModelNumberChange(e) {
           this.setState({
             modelNumber: e.currentTarget.value
           });
         }
         onPartNameChange(index, e) {
           const { parts } = this.state;
           parts[index].name = e.target.value;
           this.setState({ parts: parts });
         }
         onCheckboxChange(index, e) {
           const { parts } = this.state;
           parts[index].useOriginalPhoto = e.target.checked;
           this.setState({ parts: parts });
         }
         onGetProductsConfirmed() {
           const { url,supplierConfigId } = this.state;
           this.populatePartsData(url,supplierConfigId);
           this.setState({ confirmGetParts: false });
         }
         onProcessProducts() {
           const {
             parentCategoryId,
             modelNumber,
             modelName,
             searchBaseUrl,
             shippingInfo,
             warrentyInfo,
             descriptionFooter,
             parts,
             supplierConfigId
           } = this.state;
           let partListViewModel = {};
           partListViewModel.CategoryId        = parentCategoryId;
           partListViewModel.ModelName         = modelName;
           partListViewModel.ModelNumber       = modelNumber;
           partListViewModel.searchBaseUrl     = searchBaseUrl;
           partListViewModel.shippingInfo      = shippingInfo;
           partListViewModel.warrentyInfo      = warrentyInfo;
           partListViewModel.descriptionFooter = descriptionFooter;
           partListViewModel.Parts             = parts.filter(p=>p.processRequired===true);
           partListViewModel.supplierConfigId  = supplierConfigId;
             partListViewModel.Parts.forEach(p => {
                 p.partRelatedModels = null;
             });
           this.processPartsData(partListViewModel);
         }
         onSelectAllPhotos(e) {
           const { parts } = this.state;
           parts.forEach(p => {
             if (p.imgSrc != null) {
               p.useOriginalPhoto = e.target.checked;
             }
           });
           this.setState({ parts: parts });
         }
         onProcesCheckboxChange(index,e){
         
          const { parts } = this.state;
          parts[index].processRequired = e.target.checked;
          if(e.target.checked){
            this.setState({ parts: parts,partsSelected:true});
          }else{
            if(parts.find(p=>p.processRequired===true)!==undefined){
              this.setState({ parts: parts,partsSelected:true});
            }else{
              this.setState({ parts: parts,partsSelected:false});
            }
          }
         }
         onSelectAllParts(e) {
          const { parts } = this.state;
          parts.forEach(p => {
              p.processRequired = e.target.checked;
          });
          if(e.target.checked){
            this.setState({ parts: parts,partsSelected:true});
          }else{
            this.setState({ parts: parts,partsSelected:false});
          }
        }
         renderDetailsTable(part,index) {
           const { modelName, modelNumber,shippingInfo,warrentyInfo,expandedIndex,searchBaseUrl,descriptionFooter } = this.state;
           return (
             <table className="table table-noborder" style={{minWidth:"45vw"}}>
               <tbody style={{ fontSize: "12px" }}>
                 <tr>
                   <th>Name</th>
                   <td>
                     <textarea
                       className="part-name-textarea"
                       value={part.name}
                       onChange={e => this.onPartNameChange(index, e)}
                     ></textarea>
                   </td>
                   <td>
                     <ExpandButton
                       onClick={() =>
                         this.setState({
                           expandedIndex:
                             expandedIndex === index ? undefined : index
                         })
                       }
                       isExpanded={expandedIndex === index ? true : false}
                     />
                   </td>
                 </tr>
                 <tr style={{display:expandedIndex===index?"table-row":"none"}}>
                   <th>Title</th>
                   <td>
                     <strong>
                       {part.brand==="HP"?"":part.brand} {modelName} {modelNumber} {part.name} {part.originalSku}
                     </strong> 
                   </td>
                   <th rowSpan="3" className="align-middle">
                     <span
                       style={{
                         writingMode: "vertical-lr",
                         transform: "rotate(180deg)",
                         msWritingMode: "tb-rl"
                       }}
                     >
                       Description
                     </span>
                   </th>
                 </tr>
                 <tr style={{display:expandedIndex===index?"table-row":"none"}}>
                   <th>Body</th>
                   <td>
                     Condition:New
                     <br/>
                     Manufacturer:{part.brand}
                     <br/>
                     Ships in:{shippingInfo}
                     <br/>
                     Warranty: {warrentyInfo}
                     <br/>
                       Part Number:{" "}
                       
                         <b>{part.originalSku}</b>
                      
                     <br/>
                     Laptop Model:{" "}
                     <a href={`${searchBaseUrl}${modelName}`}>{modelName}(Click here for more parts for this model)</a>
                     
                      <div
                       dangerouslySetInnerHTML={{ __html: descriptionFooter }}
                     ></div>
                   </td>
                 </tr>

                 <tr style={{display:expandedIndex===index?"table-row":"none"}}>
                   <th>Compatible With</th>
                   <td>
                     {part.partRelatedModels && part.partRelatedModels.length?
                     part.partRelatedModels.map((p, index) => (
                       <div key={index}><a href={`${searchBaseUrl}${p.sku}`}>{p.sku}</a>-<span>{p.desciption}</span></div>
                     ))
                     :null}
                     {/* <div
                       dangerouslySetInnerHTML={{ __html: part.descrption }}
                     ></div> */}
                   </td>
                 </tr>
               </tbody>
             </table>
           );
         }
         renderpartsTable(parts) {
           const { showPhoto,processedUrl } = this.state;
             return (

                 <div className="ml-2 mr-2">
                     <Prompt when={true}
                         message="Are you sure?"
                         />
               {parts && parts.length ? (
                 <div style={{ width: "100%" }}>
                   <div>
                     <h1 id="tabelLabel float-left">Products List</h1>
                   </div>
                   <table
                     className="table table-bordered "
                     aria-labelledby="tabelLabel"
                   >
                     <thead>
                       <tr>
                         <th>#</th>
                         <th>
                         Process
                         <div>
                           <input
                             type="checkbox"
                             onChange={e => this.onSelectAllParts(e)}
                           ></input> 
                           <span style={{fontSize:"10px",fontWeight:"normal"}}>Select All</span>
                           </div> 
                          
                         </th>
                         {/* <th>Product Name</th> */}
                         {/* <th>Product Title</th> */}
                         <th>Description</th>
                         <th>Sku</th>
                         <th>Price</th>
                         <th>Image</th>
                         <th>
                         Use Photo
                          <div>
                            <input
                             type="checkbox"
                             onChange={e => this.onSelectAllPhotos(e)}
                           ></input> 
                           <span style={{fontSize:"10px",fontWeight:"normal"}}>Select All</span></div> 
                         </th>
                         <th>Is Processed</th>
                       </tr>
                     </thead>
                     <tbody style={{maxWidth:'100vw'}}>
                       {parts.map((part, index) => (
                         <Fragment key={index}>
                           <tr
                             className={part.price === 0 ? "text-danger" : ""}
                             style={{ fontSize: "14px !important" }}
                           >
                             <td rowSpan="2">{index + 1}</td>
                             <td
                               rowSpan="2"
                              
                             >
                               {" "}
                               <input
                                 type="checkbox"
                                 onChange={e => this.onProcesCheckboxChange(index, e)}
                                 checked={part.processRequired || false}
                               ></input>
                             </td>
   
                             <td rowSpan="2">
                               {this.renderDetailsTable(part,index)}
                             </td>
                             <td>{part.sku}</td>

                             <td>{part.price} AUD</td>
                             <td rowSpan="2">
                               <img
                                 src={part.imgSrc || imageUrl}
                                 width="100"
                                 alt=""
                                 onClick={() =>
                                   this.setState({ showPhoto: part.imgSrc })
                                 }
                                 className="part-photo-list"
                               ></img>
                             </td>
                             <td
                               rowSpan="2"
                               className="d-flex align-items-center"
                             >
                               {" "}
                               <input
                                 type="checkbox"
                                 onChange={e => this.onCheckboxChange(index, e)}
                                 disabled={!part.imgSrc}
                                 checked={part.useOriginalPhoto || false}
                               ></input>
                             </td>
                             <td rowSpan="2">
                              Part:  {part.isProcessed ? "Yes" : "No"}<br />
                              Image:  {part.isImageProcessed ? "Yes" : "No"}<br />
                              {part.laptopPartsUrl?<a target='_blank' href={part.laptopPartsUrl} rel="noopener noreferrer">Click here to see</a>:null}
                             </td>
                           </tr>
                           <tr>
                             <td>
                               <h6 className="font-blue">(Original)</h6>
                               {part.originalSku}
                             </td>
                             <td>
                               <h6 className="font-blue">(Original)</h6>
                               {part.originalPrice} AUD
                             </td>
                           </tr>
                         </Fragment>
                       ))}
                     </tbody>
                   </table>
                   {showPhoto && (
                     <PhotoModal
                       //title="Photo"
                       className="clearfix"
                       onClose={() => this.setState({ showPhoto: undefined })}
                       //footer: PropTypes.element,
                       photoData={showPhoto}
                     ></PhotoModal>
                   )}
                 </div>
               ) : processedUrl? (
                 <div className="text-center">
                   {" "}
                   No data found , please check url
                 </div>
               ):null}
             </div>
           );
         }
         renderLogs(){
           const { clearLogs,forceHideLogs } = this.state;
         return(<div className="container" >
           <Logs clearLogs={clearLogs} forceHideLogs={forceHideLogs}/></div>
         );
        }
        renderSelectSupplierConfigurations(){
          const { suppliersConfig,parentCategoryId } = this.state;
          return(
            <div className="input-group input-group-sm mb-3 col-lg-6">
            <div className="input-group-prepend">
              <label
                className="input-group-text"
                htmlFor="inputGroupSelect02"
              >
                Supplier Configurations
              </label>
            </div>
            <select
              className="custom-select"
              id="inputGroupSelect02"
              onChange={this.onSupplierConfigChange}
              //disabled={!parts || !parts.length}
            >
             <option  value={null} defaultValue>
                {null}
              </option>
              {suppliersConfig && suppliersConfig.length? suppliersConfig.map((config,index) => (
                <option key={config.id} value={config.id}>
                      {config.name + "_" + ((config.supplierName === 0) ? "EMPR" : (config.supplierName === 1)?"ZAND":"")}
                </option>
              )):null}
            </select>
          </div>
          );
        }
        renderSelectCategory(){
          const { parentCategoryId } = this.state;
          return (
            <div className="input-group input-group-sm mb-3 col-lg-6 mx-auto">
              <div className="input-group-prepend">
                <label
                  className="input-group-text"
                  htmlFor="inputGroupSelect01"
                >
                  Select Category
                </label>
              </div>
              <select
                className="custom-select"
                id="inputGroupSelect01"
                onChange={this.onCategoryChange}
                //disabled={!parts || !parts.length}
                value={parentCategoryId}
              >
                <option value={null}>
                  {null}
                </option>
                {categories.map(c => (
                   c.value=== parentCategoryId?
                   <option key={c.value} value={c.value}>
                    {c.text}
                  </option>:
                    <option key={c.value} value={c.value}>
                    {c.text}
                  </option>
                  
                ))}
              </select>
            </div>
          );
        }
         render() {
           const {
             url,
             parts,
             parentCategoryId,
             modelName,
             modelNumber,
             loading,
             processedUrl,
             confirmGetParts,
             supplierConfigId,
             partsSelected
           } = this.state;
           let contents = loading ? (
             <div className="text-center">
                 Reading Data from several urls... Time debends on number of
                 parts and internet speed....Could takes few minutes
                 <Timer />
                 <Spinner/>
               </div>
           ) : (
             this.renderpartsTable(parts)
           );
           return (
             <div className="">
               <div className="container">
                 <div className="row">
                 {!parts || !parts.length?
                   <Fragment>
                     <div
                       className={`input-group input-group-sm mb-3 col-6   ${
                         !validURL(url)
                           ? url === ""
                             ? ""
                             : "has-error"
                           : "has-success"
                       }`}
                     >
                       <div className="input-group-prepend">
                         <span
                           className="input-group-text"
                           id="inputGroup-sizing-sm"
                         >
                           Url
                         </span>
                       </div>
                       <input
                         type="text "
                         className=" form-control"
                         onChange={this.onInputChange}
                         value={url}
                       ></input>
                       {!validURL(url) && url !== "" && (
                         <div className="container ">
                           <span className="field-error">Invalid Url</span>
                         </div>
                       )}
                     </div>
                     {this.renderSelectSupplierConfigurations()}
                     <div className=" col-12 d-flex justify-content-center">
                       <button
                         className="btn btn-success btn-sm m-1"
                         onClick={() => {
                           url === processedUrl
                             ? this.setState({ confirmGetParts: true })
                             : this.populatePartsData(url, supplierConfigId);
                         }}
                         disabled={
                           url === "" ||
                           !validURL(url) ||
                           !supplierConfigId ||
                           loading
                         }
                       >
                         Get Products
                       </button>
                     </div>
                   </Fragment>
                   :null}

                   {parts && parts.length ? (
                     <Fragment>
                       <div className="input-group input-group-sm mb-3 col-6">
                         <div className="input-group-prepend">
                           <span className="input-group-text" id="basic-addon1">
                             Model Name
                           </span>
                         </div>
                         <input
                           type="text"
                           className="form-control"
                           onChange={this.onModelNameChange}
                           value={modelName || ""}
                           disabled={!parts || !parts.length}
                         ></input>
                       </div>

                       <div className="input-group  input-group-sm mb-3 col-6">
                         <div className="input-group-prepend">
                           <span className="input-group-text" id="basic-addon1">
                             Model Number
                           </span>
                         </div>
                         <input
                           type="text"
                           className="form-control"
                           onChange={this.onModelNumberChange}
                           value={modelNumber || ""}
                           disabled={!parts || !parts.length}
                         ></input>
                       </div>
                       {this.renderSelectCategory()}
                       <div className=" col-12 d-flex justify-content-center">
                         <button
                           className="btn btn-success btn-sm mr-1"
                           onClick={this.onProcessProducts}
                           disabled={
                             url === "" ||
                             !validURL(url) ||
                             !parentCategoryId ||
                             !modelName ||
                            // !modelNumber ||
                             !parts ||
                             parts.length === 0 ||
                            // loading ||
                             !partsSelected
                           }
                         >
                           Process Products
                         </button>
                         <button
                           className="btn btn-warning btn-sm mr-1"
                           onClick={() =>
                             this.setState({
                               parts: [],
                               processedUrl: undefined,
                               url: "",
                               clearLogs:!this.state.clearLogs,
                               supplierConfigId: undefined,
                               parentCategoryId:undefined
                             })
                           }
                         >
                           Clear Results
                         </button>
                       </div>
                     </Fragment>
                   ) : null}
                   {processedUrl === url && confirmGetParts && (
                     <ConfirmModal
                       name={"this url"}
                       question="Are you sure you want to get products of this url again"
                       onConfirm={() =>
                         this.populatePartsData(url, supplierConfigId)
                       }
                       onClose={() => this.setState({ confirmGetParts: false })}
                     />
                   )}
                 </div>
               </div>
               {this.renderLogs()}
               {contents}
             </div>
           );
         }

    async populatePartsData(url,supplierConfigId) {
           this.setState({
             parts: [],
             loading: true,
             confirmGetParts: false,
             clearLogs:!this.state.clearLogs,
             showLogs: true,
             processingUrl:url
           });
      
        const token = await authService.getAccessToken();
            fetch(`parts/post`, {
             method: "POST",
               body: JSON.stringify({ url: url, supplierConfigId: supplierConfigId, supplier:"zand"}),
             headers: !token
               ? {}
               : {
                   Authorization: `Bearer ${token}`,
                     "Content-Type": "application/json"
                 }
           });
        //const data = await handleResponse(response,this.props.history);
        //   if (data) {
        //     this.setState({
        //       parts: data.parts,
        //       modelNumber: data.modelNumber,
        //       modelName: data.modelName,
        //       searchBaseUrl:data.searchBaseUrl,
        //       shippingInfo:data.shippingInfo,
        //       warrentyInfo:data.warrentyInfo,
        //       parentCategoryId:data.categoryId,
        //       descriptionFooter:data.descriptionFooter,
        //       loading: false,
        //       processedUrl: url,
        //       forceHideLogs:!this.state.forceHideLogs
        //     });
        //   } else {
        //     this.setState({ parts: [], loading: false });
        //   }
         }
    async processPartsData(partListViewModel) {
           this.setState({ partListViewModel: null, loading: true,clearLogs:!this.state.clearLogs });
           const token = await authService.getAccessToken();
         //  const response = await
        fetch(`parts/processparts`, {
             method: "POST",
             body: JSON.stringify(partListViewModel),
             headers: !token
               ? {}
               : {
                   Authorization: `Bearer ${token}`,
                   "Content-Type": "application/json"
                 }
           });
           //const data = await handleResponse(response,this.props.history);
           //if (data) {
           //  this.setState({
           //    parts: data.parts,
           //    modelNumber: "",
           //    modelName: "",
           //    loading: false
           //  });
           //} else {
           //  this.setState({ parts: [], loading: false });
           //}
         }
    async getAllsuppliers() {
          this.setState({ suppliersConfig: null });
          const token = await authService.getAccessToken();
      
          const response = await fetch(`api/users/suppliers`, {
            method: "Get",
            headers: !token
              ? {}
              : {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json"
                }
          });
          const data = await handleResponse(response, this.props.history,false);
      
          if (data) {
            this.setState({ suppliersConfig: data });
          } else {
           // this.setState({ result: this.getFailedResult(), loading: false });
          }
        }
  }
       
