import React from 'react';
import PropTypes from 'prop-types';

export const TextArea = ({name, label, onChange, placeholder, value,rows, error,disabled=false,className,onBlur,onFocus}) => {
  let wrapperClass = 'form-group';
  if (error && error.length > 0) {
    wrapperClass += " " + 'has-error';
  }

  return (
    <div className={wrapperClass}>
      <label htmlFor={name}>{label}</label>
      <div className="field">
        <textarea
          name={name}
          className={className}
          placeholder={placeholder}
          value={value}
          rows={rows}
          onChange={onChange}
          disabled={disabled}
          onBlur={onBlur}
          onFocus={onFocus}
          >
          </textarea>
          {error? <div className="alert alert-danger">{error}</div>:null}
      </div>
    </div>
  );
};
TextArea.defaultProps = {
  className:"form-control form-control-sm",
  type: 'text',
};
TextArea.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string,
  rows:PropTypes.number,
  disabled:PropTypes.bool,
  className:PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func
};

export default TextArea;
