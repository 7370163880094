import React, { Component } from "react";
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

class UserTable extends Component {
    static getDerivedStateFromProps(nextProps, state) {
        if (nextProps.allowedUsers !== state.allowedUsers) {
            return {
                allowedUsers: nextProps.allowedUsers
            };
        }
        return null;
    }
  static displayName = UserTable.name;

  constructor(props) {
    super(props);
    this.state = {
      allowedUsers: []
    };
  }  
  render() {
    const { allowedUsers } = this.state;
    const { changeUserStatus } = this.props;
   
    return (
        <div className="container">
        
        <table className="table table-bordered">
          <thead>
          <tr>
            <th>Id</th>
            <th>Email</th>
            <th>Status</th></tr>
          </thead>
          <tbody>
            {allowedUsers.map((allowedUser, index) => (
              <tr key={index}>
                <td>{allowedUser.id}</td>
                <td>{allowedUser.email}</td>
                <td className="text-center" style={{width:"130px"}}>
                  {" "}
                  {allowedUser.isActive ? (
                    <button
                      className="btn btn-danger"
                      onClick={() => changeUserStatus(allowedUser,false)}
                    >
                      DeActivate
                    </button>
                  ) : (
                    <button
                      className="btn btn-success"
                      onClick={() => changeUserStatus(allowedUser,true)}
                    >
                      Activate
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}
UserTable.propTypes = {
    allowedUsers: PropTypes.array.isRequired,
    changeUserStatus: PropTypes.func.isRequired
};
export default withRouter(UserTable);