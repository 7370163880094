import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Modal from './Modal';
import Buttons from '../Buttons/Buttons';
import 'font-awesome/css/font-awesome.min.css';
const deleteModal = {
    title: "Confirm",
    className: "modal-dialog-centered slideDown",
    submitButton: {
        label: "Confirm",
        fontAwesome: "fa fa-check"
    },
    cancelLabel: {
        label: "Cancel",
        className: "btn btn-secondary",
        fontAwesome: "fa fa-times"
    }

}
const ConfirmModal = (props) => {
    return (
        <Modal
            {...props}
            children={
                <Fragment>
                    <h5>{props.question} &nbsp; <span className="font-blue">{props.name}</span>&nbsp;?</h5>
                    <h6>{props.note}</h6>
                </Fragment>
            }
            footer={<Buttons
                buttons={[
                    { ...deleteModal.submitButton, onClick: props.onConfirm },
                    { ...deleteModal.cancelLabel, onClick: props.onClose }
                ]} />}
        />
    );
};
ConfirmModal.defaultProps = {
    title: "Confirm",
    className: "modal-dialog-centered slideDown",
    note: ""
}
ConfirmModal.propTypes = {
    name: PropTypes.string,
    onConfirm: PropTypes.func,
    onClose: PropTypes.func,
    note: PropTypes.string,
    question: PropTypes.string
};
export default ConfirmModal;