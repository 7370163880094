import { toast } from 'react-toastify';
export  function  handleResponse(response,history, dispalyToastr = true) {

  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (response.ok && dispalyToastr) {
      toast.success('Success');
    }
     if (!response.ok) {
      
       const errorMessage = (data && data.error) || response.statusText;
       displayError(response.status, errorMessage);
       if (response.status ===401) {
         if(history){
          history.push("../authentication/login"); 
         }
      }
       return null;
     }

    return data;
  });
}
function displayError(code, errorMessage) {
  let error;
  switch (code) {
    case 400:
      error = "Bad Request !!";
      break;
    case 401:
      error = "Unauthorized !!";
      break;
    case 403:
      error = "Forbidden";
      break;
    case 404:
      error = "Not Found";
      break;
    case 408:
      error = "Request Timeout";
      break;
    case 413:
      error = "Request Entity Too Large";
      break;
    case 500:
      error = "Internal Server Error";
      break;
    default:
      error = "Unknown error";
  }

  toast.error(error + ": " + errorMessage);
}