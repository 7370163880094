import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { Empr } from './components/Empr';
import { Managment } from './components/Managment/Managment';
import  {Users}  from './components/Managment/Users';
import  {Suppliers}  from './components/Managment/Suppliers';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';

import './custom.css'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
      <Layout>
        <AuthorizeRoute exact path='/' component={Home} />
            <AuthorizeRoute path='/empr' component={Empr} />
            <AuthorizeRoute path='/managment/parts' component={Managment} />
            <AuthorizeRoute path='/managment/users' component={Users} />
            <AuthorizeRoute path='/managment/suppliers' component={Suppliers} />
            <ToastContainer />
        <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
      </Layout>
    );
  }
}
