import React, { Component,Fragment } from "react";
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import 'font-awesome/css/font-awesome.min.css';

class SupplierTable extends Component {
    static getDerivedStateFromProps(nextProps, state) {
        if (nextProps.suppliers !== state.suppliers) {
            return {
                suppliers: nextProps.suppliers
            };
        }
        return null;
    }
  static displayName = SupplierTable.name;

  constructor(props) {
    super(props);
    this.state = {
      suppliers: []
    };
  }  
  render() {
    const { suppliers,onEdit,onDelete,onEditReplacmentWord,onDeleteReplacmentWord,onCreateReplacmentWord } = this.props;
   
    return (
      <div className="container">
        <table
          className="table table-bordered table-striped"
          style={{ width: "100%" }}
        >
          <thead>
            <tr>
              <th>Id</th>
              <th>Name</th>
              <th>Supplier</th>
              <th>Shipping Info</th>
              <th>Warrenty Info</th>
              <th>Description Footer</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {suppliers.map((supplier, index) => (
              <Fragment key={index}>
                <tr>
                  <td rowSpan="2">{supplier.id}</td>
                  <td>{supplier.name}</td>
                  <td>
                    {supplier.supplierName === 0
                      ? "EMPR"
                      : supplier.supplierName === 1
                      ? "ZAND"
                      : ""}
                  </td>
                  <td>{supplier.shippingInfo}</td>
                  <td>{supplier.warrentyInfo}</td>
                  <td>{supplier.descriptionFooter}</td>
                  <td rowSpan={2} style={{ verticalAlign: "middle" }}>
                    <button
                      className={"btn btn-outline-primary mb-1"}
                      style={{ width: "100%" }}
                      onClick={() => onEdit(supplier)}
                    >
                      <i className="fa fa-edit"></i> edit
                    </button>
                    <button
                      className={"btn btn-outline-danger mb-1"}
                      style={{ width: "100%" }}
                      onClick={() => onDelete(supplier)}
                    >
                      <i className="fa fa-trash"></i> delete
                    </button>
                  </td>
                </tr>
                <tr>
                  <td colSpan={5}>
                    <table style={{ width: "100%", fontSize: "10px", border:"2px solid black"}}>
                      <thead>
                        <tr style={{backgroundColor:"lightcyan"}}>
                          <th>Min</th>
                          <th>Max</th>
                          <th>Shipping Fee</th>
                          <th>Profit Percetage</th>
                        </tr>
                      </thead>
                      <tbody>
                        {supplier.priceMargins
                          ? supplier.priceMargins.map((p, index) => (
                              <tr key={index}>
                                <td>{p.min}</td>
                                <td>{p.max !== 0 ? p.max : "Unlimited"}</td>
                                <td>{p.fixed}</td>
                                <td>{p.percentage}</td>
                              </tr>
                            ))
                          : null}
                        {/* <tr>Replacement Words</tr> */}
                      </tbody>
                    </table>
                    <table style={{ width: "100%", fontSize: "10px",border:"2px solid black" }}>
                      <thead>
                        <tr style={{backgroundColor:"lightcyan"}}>
                          <th>Original</th>
                          <th>Replacement</th>
                          <th colSpan={1}><button  className="btn btn-info float-right"
                                  onClick={() => onCreateReplacmentWord(supplier)}
                                  >
                                    <i className="fa fa-plus"></i> Create New Replacement
                                  </button></th>
                          
                        </tr>
                      </thead>
                      <tbody>
                        {supplier.words
                          ? supplier.words.map((w, index) => (
                              <tr key={index}>
                                <td>{w.original}</td>
                                <td>{w.replacement}</td>
                                {/* <td>
                                  <button className={"btn btn-outline-primary"}
                                  onClick={() => onEditReplacmentWord(w)}>
                                    <i className="fa fa-edit"></i>edit
                                  </button>
                                </td> */}
                                <td>
                                  <button className={"btn btn-outline-danger float-right"}
                                  onClick={() => onDeleteReplacmentWord(w)}>
                                    <i className="fa fa-trash"></i> delete
                                  </button>
                                </td>
                              </tr>
                            ))
                          : null}
                      </tbody>
                    </table>
                  </td>
                </tr>
              </Fragment>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}
SupplierTable.propTypes = {
    suppliers: PropTypes.array.isRequired,
    onEdit: PropTypes.func.isRequired,
    onEditReplacmentWord: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onDeleteReplacmentWord : PropTypes.func.isRequired,
    onCreateReplacmentWord : PropTypes.func.isRequired
};
export default withRouter(SupplierTable);