import React, { Component } from 'react';
import authService from '../api-authorization/AuthorizeService'
import DeleteModal from '../Modals/DeleteModal';
import Spinner from '../Common/Spinner';
import Timer from '../Common/Timer';
import {handleResponse} from '../../helpers/handleResponse';
import Logs from '../SignalR/Logs';
export class Managment extends Component {
    static displayName = Managment.name;

    constructor(props) {
        super(props);
        this.state = {
            result: {}, loading: false, sku: "", deletePartSku: null ,clearLogs:false};
        this.onInputChange = this.onInputChange.bind(this);
        this.onDeleteConfirmed = this.onDeleteConfirmed.bind(this);
    }

    componentDidMount() {
    }
    onDeleteConfirmed() {
        const { sku } = this.state;
        this.deletePart(sku);
        this.setState({ deletePartSku: undefined });
    }
    onInputChange(e) {
        this.setState({
            sku: e.target.value
        });
    }
    static renderResult(result) {
        return (
            <div className="container">
                {result?
                    <div>
                        <div>{result.code}</div>
                        <div>{result.text}</div>
                    </div>
                    :null
                }
              
            </div>

        );
    }

    render() {
        const { sku, deletePartSku } = this.state;
        let contents = this.state.loading
            ? <div><Spinner/><div className="container d-flex justify-content-center"><Timer/></div></div>
            : Managment.renderResult(this.state.result);
        return (
            <div>
                <div className={`input-group input-group-sm mb-3 d-flex justify-content-center ${sku === "" ? "" : "has-success"}`} >
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="inputGroup-sizing-sm">Sku</span>
                    </div>
                    <input type="text " className="input-group" onChange={this.onInputChange} style={{ width: "60%" }} value={sku}></input>
                    <div className="input-group-append">
                        <button className="btn btn-success" onClick={() => this.getProductUrl(sku)} disabled={sku === ""}>Get Part Url</button>
                    </div>
                    <div className="input-group-append">
                        <button className="btn btn-danger" onClick={() => this.setState({ deletePartSku: sku })} disabled={sku === ""}>Delete Part</button>
                    </div>
                   
                </div>
                <div className=" mb-3 d-flex justify-content-center">
                    <button className="btn btn-warning" onClick={() => this.syncCategories()}>Sync Categories</button>
                </div>
                {deletePartSku &&
                    <DeleteModal
                    name={deletePartSku}
                    question="Are you sure you want to delete Part :"
                    onConfirm={this.onDeleteConfirmed}
                    onClose={() => this.setState({ deletePartSku: null })}/>
                }
                {contents}
                <div className="container"><Logs clearLogs={this.state.clearLogs}/></div>
            </div>
        );
    }

    async deletePart(sku) {
        this.setState({ result: null, loading: true, sku: "",clearLogs:!this.state.clearLogs });
        const token = await authService.getAccessToken();
        const response = await fetch(`parts/delete/${sku}`, {
            method: "Delete",
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        });
        const data = await handleResponse(response,null);
        if(data){
            this.setState({ result: data, loading: false });
        }else{
            this.setState({ result: this.getFailedResult(), loading: false });
        }
    }
    async getProductUrl(sku) {
        this.setState({ result: null, loading: true, sku: "",clearLogs:!this.state.clearLogs });
        const token = await authService.getAccessToken();
    const response = await fetch(`parts/get/${sku}`, {
            method: "Get",
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        });
        const data = await handleResponse(response,this.props.history);
        if(data){
            data.text = (data.code === "Success") ? <a className="link" href={data.text} target="_blank" rel="noopener noreferrer">{data.text}</a> : <span>{data.text}</span>
            this.setState({ result: data, loading: false });
        }else{
            this.setState({ result: this.getFailedResult(), loading: false });
        }
      
    }
    async syncCategories() {
        this.setState({ result: null, loading: true, sku: "",clearLogs:!this.state.clearLogs });
        const token = await authService.getAccessToken();
        const response = await fetch(`parts/SyncronizeCategories`, {
            method: "Get",
            headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
        });
        const data = await handleResponse(response,null);
        if(data){
            this.setState({ result: data, loading: false });
        }else{
            this.setState({ result: this.getFailedResult(), loading: false });
        }
        
    }
    getFailedResult(){
        let result={};
        result.code = < div className = "font-weight-bold text-danger" > Failed </div>;
        result.text = < div className = "text-danger" > Something went wrong </div>;
        return result;
    }
  
}
